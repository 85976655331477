<template>
    <div>
        <div class="content">
            <div class="container-fluid mt-3">
                <card  class="no-border-card"  footer-classes="pb-2" v-loading.fullscreen.lock="loading">
                    <template slot="header">
                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-6 col-12">
                                <h2 class="mb-0">Concord Hospitality Group</h2>
                            </div>
                        </div>
                    </template>
                    <div>
                        <div class="user-eltable">
                            <el-table :data="locations" class="compGrid" header-row-class-name="thead-light" highlight-current-row id="tbl1" role="table" row-key="id" stripe>
                                <el-table-column label="Name" prop="name">
                                    <template slot-scope="props">
                                        <span class="text-capitalize">{{ props.row.name }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Action">
                                    <template slot-scope="props">
                                        <span class="text-blue linkColor comppaniescount">
                                            <el-tooltip content="Edit" placement="top">
                                                <router-link :to="`/locations/${props.row.id}/details`">
                                                    <i class="text-default fa fa-pencil-square-o"></i>
                                                </router-link>
                                            </el-tooltip>
                                        </span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                </card>
            </div>
        </div>
    </div>
</template>
<script>
import {Table, TableColumn} from "element-ui";
import "sweetalert2/src/sweetalert2.scss";

let timeout = null;
export default {
    name: "concord-hospitality-group",
    // mixins: [serverSidePaginationMixin],
    components: {
        [Table.name]: Table,
        [TableColumn.name]: TableColumn
    },
    data() {
        return {
            loading: false,
            locations: [],
        };
    },
    created: function () {
        this.getChildLocations();
    },
    methods: {
        getChildLocations: function() {
            this.$http.get("location/fetch-child-location", {
                params: {
                    companyId: process.env.VUE_APP_CONCORD_HOSPITALITY_COMPANY_ID
                }
            }).then(resp => {
                this.locations = resp.data;
            }).catch(error => {
                console.log(error);
            });
        },
    }
};
</script>

